.sidebar {
    width: 200px;
    background-color: rgb(35, 24, 90);
    height: 100vh;
    position: fixed;
    top: 16.5%;
    left: 0;
    display: flex;
    flex-direction: column;
}
@media screen and (max-width:858px){
    .sidebar {
display: none;
    }
    .images-container{
        margin-left:20px;
    }
    .donate-container{
        margin-left:20px;
    }
}

.images-container{
    margin-left:200px;
}
.donate-container{
    margin-left:200px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    transition: background-color 0.3s;
    color:white;
}

.tab:hover {
    background-color: #e0e0e0;
    color:rgb(35, 24, 90);
}

.content {
    margin-left: 200px;
    padding: 20px;
}

#imagesContent {
    display: block;
}

#donateContent {
    display: none;
}

.image-item {
    margin-bottom: 20px;
}

.image-item img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
