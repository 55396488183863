/* AdminLoginPage.css */

@keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  /* body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: linear-gradient(135deg, #1e3c72, #2a5298);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
  } */

  .login-main-container{
    background: linear-gradient(135deg, #1e3c72, #2a5298);
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-container {
    width: 100%;
    max-width: 800px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    animation: float 6s ease-in-out infinite;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   

  }
  
  .admin-avatar {
    margin-bottom: 1rem;
    animation: float 6s ease-in-out infinite;
    transform: rotateY(20deg);
    width:40%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  